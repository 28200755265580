import moment from 'moment'
import { AppConfig } from '../Config'
import { constant } from '../_config/constant'

function logout() {
    localStorage.removeItem(constant.TOKEN_KEY)
    localStorage.removeItem(constant.DATA_KEY)
    localStorage.removeItem(constant.TEMP_REF)
    window.location.href('/')
}

export function handleResponse(response) {
    const data = response.data

    if (data.statusCode === 401 || data.statusCode === 403) {
        logout()
    }

    if (!(data.statusCode + '').startsWith(2)) {
        const error = (data && data.userMessage) || response.statusText
        return Promise.reject(error)
    }
    return response
}

export function handleCatch(func) {
    return (...args) => {
        return func(...args).catch((err) => {})
    }
}

export function handleErrorResponse(error) {
    const res = error.response
    if (res?.status === 403) {
        logout()
        window.location.pathname = '/login'
    } else window.location.pathname = '/login'
}

export function processMediaUrl(inputString) {
    // Regular expression for basic URL validation
    const urlRegex = /^(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*$/

    // Test if the input string matches the URL pattern
    if (urlRegex.test(inputString)) {
        return inputString // It's already a URL, return as is
    } else {
        // Append "website.com/" to the input string
        const resultUrl = `${AppConfig.s3Endpoint}media/${inputString}`
        return resultUrl
    }
}

export function getHourDifference(latestDate, oldDate) {
    const timeDifference = oldDate.getTime() - latestDate.getTime()
    const hourDifference = timeDifference / (1000 * 60 * 60)
    return hourDifference
}

export function calculateDayDifference(startTime, endTime) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000 // Number of milliseconds in a day
    const timeDifferenceInMilliseconds = endTime - startTime
    const dayDifference = timeDifferenceInMilliseconds / millisecondsPerDay
    return dayDifference
}

export function calculateMinutesDifference(startDate, endDate) {
    // Convert dates to milliseconds
    const startMillis = new Date(startDate).getTime()
    const endMillis = new Date(endDate).getTime()

    // Calculate the difference in milliseconds
    const timeDifferenceMillis = endMillis - startMillis

    // Convert milliseconds to minutes
    const minutesDifference = timeDifferenceMillis / (1000 * 60)

    return minutesDifference
}

export function isBookingUnder7Day(checkInDate, timezoneStr) {
    const dateObj = new Date(checkInDate)
    const dateStr = moment(dateObj).format('YYYY-MM-DD')
    const localDateTime = moment.tz(dateStr + ' 10:00', 'YYYY-MM-DD HH:mm', timezoneStr)
    const utcDateTime = localDateTime.utc()
    let checkIn = utcDateTime.toDate()
    const sevenDaysAgo = moment().tz(timezoneStr).add(7, 'day').toDate()
    return checkIn < sevenDaysAgo
}
